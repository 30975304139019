<template>
  <nav-bar />

  <div class="container">
    <router-view />
  </div>
</template>

<script>
import NavBar from '@/components/NavBar.vue';

export default {
  components: {
    NavBar
  }
}
</script>

<style lang="scss">
@import './assets/css/theme.scss';
@import url('https://unpkg.com/boxicons@2.1.1/css/boxicons.min.css');
</style>
import authService from '../services/auth.service';

const user = JSON.parse(localStorage.getItem('user'));
const initialState = user 
    ? { status: { loggedIn: true }, user }
    : { status: { loggedIn: false}, user: null };

export const auth = {
    namespaced: true,
    state: initialState,
    actions: {
        login({ commit }, user) {
            return authService.login(user).then(
                user => {
                    commit('loginSuccess', user);
                    return Promise.resolve(user);
                },
                error => {
                    commit('loginFailure');
                    return Promise.reject(error);
                }
            );
        },
        logout({ commit }) {
            authService.logout();
            commit('logout');
        },
        refreshToken({ commit }, jwtToken) {
            commit('refreshToken', jwtToken);
        }
    },
    mutations: {
        loginSuccess(state, user) {
            state.status.loggedIn = true;
            state.user = user;
        },
        loginFailure(state) {
            state.status.loggedIn = false;
            state.user = null;
        },
        logout(state) {
            state.status.loggedIn = false;
            state.user = null;
        },
        refreshToken(state, jwtToken) {
            state.status.loggedIn = true;
            state.user = { ...state.user, jwtToken: jwtToken };
        }
    }
};
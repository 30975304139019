import { createRouter, createWebHashHistory } from 'vue-router';
import LoginView from '../views/LoginView.vue';

const routes = [
	{
		path: '/login',
		name: 'login',
		component: LoginView
	},
	// ### Locations routes ###
	{
		path: '/',
		alias: '/locations',
		name: 'locations',
		component: () => import('../views/locations/IndexView.vue')
	},
	{
		path: '/locations',
		name: 'add-location',
		component: () => import('../views/locations/CreateView.vue')
	},
	{
		path: '/locations/:locationId',
		name: 'delete-location',
		component: () => import('../views/locations/DeleteView.vue')
	},
	{
		path: '/locations/:locationId',
		name: 'update-location',
		component: () => import('../views/locations/UpdateView.vue')
	},
	// ### Buildings routes ###
	{
		path: '/locations/:locationId/buildings',
		name: 'buildings',
		component: () => import('../views/buildings/IndexView.vue')
	},
	{
		path: '/locations/:locationId/buildings',
		name: 'add-building',
		component: () => import('../views/buildings/CreateView.vue')
	},
	{
		path: '/locations/:locationId/buildings/:buildingId',
		name: 'delete-building',
		component: () => import('../views/buildings/DeleteView.vue')
	},
	{
		path: '/locations/:locationId/buildings/:buildingId',
		name: 'update-building',
		component: () => import('../views/buildings/UpdateView.vue')
	},
	// ### Registers routes ###
	{
		path: '/locations/:locationId/buildings/:buildingId/registers',
		name: 'registers',
		component: () => import('../views/registers/IndexView.vue')
	},
	{
		path: '/locations/:locationId/buildings/:buildingId/registers',
		name: 'add-register',
		component: () => import('../views/registers/CreateView.vue')
	},
	{
		path: '/locations/:locationId/buildings/:buildingId/registers/:registerId',
		name: 'delete-register',
		component: () => import('../views/registers/DeleteView.vue')
	},
	{
		path: '/locations/:locationId/buildings/:buildingId/registers/:registerId',
		name: 'update-register',
		component: () => import('../views/registers/UpdateView.vue')
	},
	// ### Analyses routes ###
	{
		path: '/locations/:locationId/buildings/:buildingId/registers/:registerId/analyses',
		name: 'analyses',
		component: () => import('../views/analyses/IndexView.vue')
	},
	{
		path: '/locations/:locationId/buildings/:buildingId/registers/:registerId/analyses',
		name: 'add-analyse',
		component: () => import('../views/analyses/CreateView.vue')
	},
	{
		path: '/locations/:locationId/buildings/:buildingId/registers/:registerId/analyses/:analyseId',
		name: 'delete-analyse',
		component: () => import('../views/analyses/DeleteView.vue')
	},
	{
		path: '/locations/:locationId/buildings/:buildingId/registers/:registerId/analyses/:analyseId',
		name: 'update-analyse',
		component: () => import('../views/analyses/UpdateView.vue')
	},
	// ### Registers rows routes ###
	{
		path: '/locations/:locationId/buildings/:buildingId/registers/:registerId/rows',
		name: 'registers-rows',
		component: () => import('../views/registers-rows/IndexView.vue')
	},
	{
		path: '/locations/:locationId/buildings/:buildingId/registers/:registerId/rows/date/:date',
		name: 'add-register-row',
		component: () => import('../views/registers-rows/CreateView.vue')
	},
	{
		path: '/locations/:locationId/buildings/:buildingId/registers/:registerId/rows/:rowId',
		name: 'delete-register-row',
		component: () => import('../views/registers-rows/DeleteView.vue')
	},
	{
		path: '/locations/:locationId/buildings/:buildingId/registers/:registerId/rows/:rowId',
		name: 'update-register-row',
		component: () => import('../views/registers-rows/UpdateView.vue')
	}
];

const router = createRouter({
	//history: createWebHistory(process.env.BASE_URL),
  history: createWebHashHistory(),
	routes
});

// redirect to login page if not logged in and trying to access a restricted page
router.beforeEach((to, from, next) => {
	const publicPages = ['/login'];
	const authRequired = !publicPages.includes(to.path);
	const loggedIn = localStorage.getItem('user');

	if (authRequired && !loggedIn) {
		return next('/login');
	}

	next();
});

export default router;
export const nav = {
    namespaced: true,
    state: {
        title: '',
        callbackUrl: '',
    },
    actions: {
        update({ commit }, payload) {
            commit('setTitle', payload.title);
            commit('setCallbackUrl', payload.callbackUrl);
        }
    },
    mutations: {
        setTitle(state, title) {
            state.title = title;
        },
        setCallbackUrl(state, url) {
            state.callbackUrl = url;
        }
    }
};
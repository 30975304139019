import apiError from './api-error';
import axiosInstance from './axios-common';
import tokenService from "./token.service";

class AuthService {
  login(user) {
    return axiosInstance
      .post('/users/authenticate', {
        username: user.username,
        password: user.password
      }, { withCredentials: true })
      .then(
        response => {
          if (response.data.jwtToken)
            tokenService.setUser(response.data);

          return response.data;
        },
        error => Promise.reject(apiError.getMessage(error))
      );
  }

  logout() {
    tokenService.removeUser();
  }

  // Add fonctions: Register, Reset password, Recovered password etc...
}

export default new AuthService();
<template>
  <button class="btn btn-primary btn-block" :disabled="loading">
    <span v-show="loading" class="spinner-border spinner-border-sm"></span>
    <span><slot>Submit</slot></span>
  </button>
</template>

<script>
export default {
  name: 'LoaderButton',
  props: {
    loading: false
  }
}
</script>

<style scoped lang="scss">
  button span.spinner-border {
    margin-right: 10px;
  }
</style>
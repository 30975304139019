<template>
  <div class="form-container col-md-12">
    <Form @submit="handleLogin" :validation-schema="schema">
      <div class="form-group">
        <label for="username">Identifiant</label>
        <Field name="username" type="text" class="form-control" />
        <ErrorMessage name="username" class="error-feedback" />
      </div>
      <div class="form-group">
        <label for="password">Mot de passe</label>
        <Field name="password" type="password" class="form-control" />
        <ErrorMessage name="password" class="error-feedback" />
      </div>

      <div class="form-group">
        <loader-button :loading="loading">Se connecter</loader-button>
      </div>
      
      <div class="form-group">
        <div v-if="message" class="alert alert-danger" role="alert">
          {{ message }}
        </div>
      </div>
    </Form>
  </div>
</template>

<script>
import LoaderButton from '@/components/LoaderButton.vue';
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";

export default {
  name: 'LoginView',
  components: {
    LoaderButton, Form, Field, ErrorMessage
  },
  data() {
    const schema = yup.object({
      username: yup.string().required("Le pseudo est requis !"),
      password: yup.string().required("Le password est requis !"),
    });

    return {
      loading: false,
      message: "",
      schema
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    }
  },
  created() {
    if (this.loggedIn)
      this.$router.push("/");

    this.$store.dispatch('nav/update', { title: 'Authentification' });
  },
  methods: {
    handleLogin(user) {
      this.loading = true;
      this.$store.dispatch("auth/login", user).then(
        () => {
          this.$router.push("/");
        },
        (error) => {
          this.loading = false;
          this.message = error;
        }
      );
    }
  }
}
</script>
import { createStore } from 'vuex';
import { auth } from "./auth.module";
import { nav } from "./nav.module";

const store = createStore({
  modules: {
    auth,
    nav
  }
});

export default store;
<template>
    <div class="navbar">
        <router-link v-if="callbackUrl" :to="callbackUrl">
            <i class='bx bx-left-arrow-alt bx-sm' ></i>
        </router-link>
        
        <h5>{{ title }}</h5>

        <button v-show="loggedIn" @click="logout">
            <i class='bx bx-log-out-circle bx-sm'></i>
        </button>
    </div>
</template>

<script>
export default {
  name: 'NavBar',
  computed: {
    loggedIn() {
        return this.$store.state.auth.status.loggedIn;
    },
    title() {
        return this.$store.state.nav.title;
    },
    callbackUrl() {
        return this.$store.state.nav.callbackUrl;
    }
  },
  methods: {
    logout: function() {
      this.$store.dispatch('auth/logout');
      this.$router.push('/login');
    }
  }
}
</script>

<style scoped lang="scss">
.navbar {
  padding: 30px;
  background-color: #4b715f;
  color: white;
  flex-wrap: inherit;

    h1, h2, h3, h4, h5 {
      margin: 0 !important;
      text-align: center;
    }

    a, button {
        display: flex;
        border: 0;
        background: transparent;
    }

    i {
        color: white;
    }
}
</style>
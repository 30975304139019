class TokenService {
	getLocalJwtToken() {
		const user = JSON.parse(localStorage.getItem("user"));
		return user?.jwtToken;
	}

	updateLocalJwtToken(token) {
		let user = JSON.parse(localStorage.getItem("user"));
		user.jwtToken = token;
		localStorage.setItem("user", JSON.stringify(user));
	}

	getUser() {
		return JSON.parse(localStorage.getItem("user"));
	}

	setUser(user) {
		localStorage.setItem("user", JSON.stringify(user));
	}

	removeUser() {
		localStorage.removeItem("user");
	}
};

export default new TokenService();
import axiosInstance from './axios-common';
import tokenService from "./token.service";

const setup = (store) => {
	axiosInstance.interceptors.request.use(
		(config) => {
			const token = tokenService.getLocalJwtToken();
			if (token)
				config.headers["Authorization"] = 'Bearer ' + token;

			return config;
		},
		(error) => {
			return Promise.reject(error);
		}
	);

	axiosInstance.interceptors.response.use(
		(res) => {
			return res;
		},
		async (err) => {
			const originalConfig = err.config;
			if (originalConfig.url !== "/users/authenticate" && err.response) {
				// Access Token was expired.
				if (err.response.status === 401 && !originalConfig._retry) {
						// Use of the retry variable to handle infinite loop.
						originalConfig._retry = true;

						try {
								const rs = await axiosInstance.post("/users/refresh-token", {}, { withCredentials: true });
								const jwtToken = rs.data.jwtToken;
								store.dispatch('auth/refreshToken', jwtToken);
								tokenService.updateLocalJwtToken(jwtToken);

								return axiosInstance(originalConfig);
						} 
						catch (_error) {
								return Promise.reject(_error);
						}
				}
			}
			return Promise.reject(err);
		}
	);
};

export default setup;